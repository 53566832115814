<template>
        <div>

                <app-view-table title="Notifications" :isTitle="true" :fields="fields" :items="items" :filters="filters" :loading="loading_list" :topFiltersCount="1" class="mb-3">

                    <template v-slot:menu-items="cell">
                        <b-dropdown-item @click="setStatus(cell.data.item)">Set status</b-dropdown-item>
                    </template>

                    <template v-slot:filter-col-1>
                        
                      <b-button-group  size="sm">
                          <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': status !== 1, 'app-list-button-selected':  status === 1 }" @click="status = 1">Shifts</b-btn>
                          <b-btn class="app-input-min-height app-list-button ml-n1" v-bind:class="{ 'btn-secondary-grey': status !== 2, 'app-list-button-selected':  status === 2 }" @click="status = 2">Assignments</b-btn>
                          <b-btn class="app-input-min-height app-list-button ml-n1" v-bind:class="{ 'btn-secondary-grey': status !== 3, 'app-list-button-selected':  status === 3 }" @click="status = 3">Hours</b-btn>
                      </b-button-group>                             

                    </template>

                    <template v-slot:buttons>
                        <span class="app-card-tittle">Total: {{total}}</span>
                    </template>

                </app-view-table>

        </div>
</template>
<script>
import axios from "@axios";
import moment from "moment";

export default {

    components: {
        
    },

    created(){
    },

    mounted(){
        this.getList();
    },

    props: ["refresh"],

    computed: {

        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        },
    },

    watch: {
        "refresh": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "items.refresh": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "status": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },
        
    },  

    data(){
        return{
            id_person_data_main: this.$route.params.id_person_data_main,
            loading: false,
            loading_list: false,
            items: {list: [], total: null, refresh :0},

            filters: {
                sorting: {
                        field_name: '',
                        sort_direction: 0,
                        changed: 0
                },
                search: "",
                page: 1,
                changed: 0,
                per_page: 10,
                total: 0,
                period: {
                    date_from: null,
                    date_to: null
                }

            },

            fields:[
                { key: "item_type_name", label: 'Naam', thStyle: { "min-width": "250px", "max-width": "250px" }, visible: true, filtering: false, sorting:false},
                { key: "department_name", label: this.trans('department',182), thStyle: { "width": "100%" }, visible: true, filtering: false, sorting:false},
                { key: "date_from", label: this.trans('date',182), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: false, sorting:false},
                { key: "time_from", label: this.trans('planning-begin',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: false, sorting:false},
                { key: "time_to", label: this.trans('planning-end',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: false, sorting:false},
            ],

            status: 1,
            total: null
        }
    },

    methods: {

        getList(){
            this.loading_list = true;

                axios
                    .post('v1/notification/getList', {
                        id_person_data_main: this.id_person_data_main,
                        status: this.status,
                    })
                    .then((res) => {
                        this.items.list = res.data;

                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.getTotal()
                    });
               
            
        },

        getTotal(){

                axios
                    .post('v1/notification/getTotal', {
                        id_person_data_main: this.id_person_data_main,
                        status: this.status,
                    })
                    .then((res) => {
                        this.total = res.data.total;

                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading_list = false;
                    });
               
            
        },

        setStatus(item){
            this.loading_list = true;

                axios
                    .post('v1/notification/setStatus', {
                        id_person_data_main: this.id_person_data_main,
                        item_id: item.item_id,
                        item_type: this.status,
                        status: 0,
                    })
                    .then((res) => {
                        this.getList();

                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading_list = false;
                    });
               
            
        },

        openShift(item){
            this.mShift.args.id_shift_data_main = item.item_id;
            this.mShift.args.id_person_data_main = this.id_person_data_main;
            this.mShift.show = true;
        },


        closeModal() {
            this.$emit("update:show", false);
        },

    }
    
}
</script>
<style scoped>

</style>