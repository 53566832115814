<template>
    <div>
        <b-row class="mb-2">
            <b-col >
                 <div class="app-card-tittle app-local-title-height">
                        <div> Hours</div>
                </div>
            </b-col>
            <b-col cols="2" end class="pr-0">
                <b-input-group class="app-local-input-group">
                    <b-input-group-prepend class="app-local-prepend" :class="{'app-local-prepend-filled' : month != null}">
                        <b-button variant="transparent" @click="previous" class="app-input-button-group app-icon-button app-local-left-button" :class="{'app-input-button-group-filled': month != null}"><b-icon class="custom-icon-color" icon="arrow-left"></b-icon></b-button>
                        <b-button variant="transparent" @click="next" class="app-input-button-group app-icon-button app-local-left-button" style="border-left: none;" :class="{'app-input-button-group-filled': month != null}"><b-icon class="custom-icon-color" icon="arrow-right"></b-icon></b-button>
                    </b-input-group-prepend>
                    <app-date v-model="month" type="month" format="MMMM YYYY" placeholder="MMMM YYYY"  :isDefaultFormat="false" class="app-local-input flex-grow-1" :key="refresh_month" />
                </b-input-group>
            </b-col>
            <b-col cols="auto" end>
                <app-refresh :refresh.sync="items.refresh" :loading.sync="loading_list" />
            </b-col>
        </b-row>

        <app-view-table title="Hours" :isTitle="true" :isTopBar="false" :groupped="items.list.length > 0" :fields="fields" :items="items" :filters="filters" :loading="loading_list" :topFiltersCount="2" :customColumns="custom_columns" class="mb-3">

            <template v-slot:menu-items="cell">
                <b-dropdown-item @click="openDetails(cell.data.item)">{{trans('to-service',200)}}</b-dropdown-item>
                <b-dropdown-item @click="change(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                <b-dropdown-item @click="openComponents(cell.data.item)">{{trans('fp-components',172)}}</b-dropdown-item>
            </template>

            <template v-slot:is_hours_registered="cell">
                <span v-if="cell.data.item.is_hours_registered == 0">{{trans('no-option',3)}}</span>
                <span v-else-if="cell.data.item.is_hours_registered == 1">{{trans('yes-option',3)}}</span>
            </template>

            <template v-slot:filter-col-1 > 
                
            </template>

        </app-view-table>

        <people-person-profile-mobile-hours-details v-if="mShift.show" :show.sync="mShift.show" :args.sync="mShift.args" :result.sync="mShift.result"  :return.sync="mShift.return" />
        <people-person-profile-mobile-hours-change v-if="mHoursChange.show" :show.sync="mHoursChange.show" :args.sync="mHoursChange.args" :result.sync="mHoursChange.result"  :return.sync="mHoursChange.return" />
        <people-person-profile-mobile-hours-components v-if="mHoursComponents.show" :show.sync="mHoursComponents.show" :args.sync="mHoursComponents.args" :result.sync="mHoursComponents.result"  :return.sync="mHoursComponents.return" /> 
    </div>
</template>
<script>


import moment from "moment";
import axios from "@axios";
import PeoplePersonProfileMobileHoursDetails from './PeoplePersonProfileMobileHoursDetails.vue';
import PeoplePersonProfileMobileHoursChange from './PeoplePersonProfileMobileHoursChange';
import PeoplePersonProfileMobileHoursComponents from './PeoplePersonProfileMobileHoursComponents.vue'

export default {
    components: {
        PeoplePersonProfileMobileHoursDetails,
        PeoplePersonProfileMobileHoursChange,
        PeoplePersonProfileMobileHoursComponents,
    },

    created(){
        this.month = new Date();
        this.filters.month = moment().format('MM');
        this.filters.year = moment().format('YYYY');
        this.getList();
    },

    data() {
        return {
            custom_columns: ['is_hours_registered'],
            id_person_data_main: this.$route.params.id_person_data_main,
            items: {list: [], refresh: 0},
            loading_list: false,

            filters: {
                sorting: {
                        field_name: '',
                        sort_direction: 0,
                        changed: 0
                },
                search: "",
                page: 1,
                changed: 0,
                per_page: 10,
                total: 0,
                period: {
                    date_from: null,
                    date_to: null
                },
                group_by: 'date_from',
                month: null,
                year: null,

            },
            month: null,

            fields:[
                { key: "client_name", label: this.trans('organization',169), thStyle: { "width": "100%" }, visible: true, filtering: false, filtering_name: "client_names"},
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: false, filtering_name: "functions"},
                { key: "date_from", label: this.trans('date',182), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: false, filtering_name: "dates"},
                { key: "time_from", label: this.trans('planning-begin',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: false},
                { key: "time_to", label: this.trans('planning-end',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: false},
                { key: "is_hours_registered", label: 'Registered', thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: false},
            ],

            mShift: {show: false, args: {id_person_data_main: null, id_shift_data_main: null}, result: 0, return: null},
            mHoursChange: {show: false, args: {
                    id_shift_data_main: null,
                    hour_from: null,
                    hour_to: null,
                    pause: null,
                    description: null,
            }, result: 0, return: null},
            mHoursComponents: {show: false, args: {id_shift_data_main: null}, result: 0, return: null},
            refresh_month: 0,
        }
    },

    watch:{
        "items.refresh": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "month": {
            handler: function(val){
                if(val != null){
                    var d = moment(val);
                    this.filters.month = d.format('MM');
                    this.filters.year = d.format('YYYY');
                    this.getList();
                }
            }
        },

        "mHoursChange.result":{
            handler: function(val){
                if(val > 0){
                    this.getList();
                    this.mHoursChange.result = 0;
                }
            }
        }

    },

    methods:{
        getList(){
            this.loading_list = true;

                axios
                    .post('v1/declaration/getDashboard', {
                        id_person_data_main: this.id_person_data_main,
                        month: this.filters.month,
                        year: this.filters.year
                    })
                    .then((res) => {
                        //this.items = res.data.items;
                        this.getShifts(res.data.items);

                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        
                    });
               
            
        },

        getShifts(items){
            this.items.list = [];
            var shifts = Object.entries(items); 
            for(var i = 0; i < shifts.length; i++){
                this.items.list.push(...shifts[i][1].shifts)
            }
            
            this.items.list.forEach(item => {
                item.date_from = moment(item.date_from, 'YYYY-MM-DD').format('DD-MM-YYYY');
            });
            this.loading_list = false;
        },

        openDetails(item){
            this.mShift.args.id_shift_data_main = item.id_shift_data_main;
            this.mShift.args.id_person_data_main = this.id_person_data_main;
            this.mShift.show = true;
        },

        change(item){
            this.mHoursChange.args.id_shift_data_main = item.id_shift_data_main;
            this.mHoursChange.args.hour_from = item.time_from;
            this.mHoursChange.args.hour_to = item.time_to;
            
            this.mHoursChange.show = true;
        },

        openComponents(item){
            this.mHoursComponents.args.id_shift_data_main = item.id_shift_data_main;
            this.mHoursComponents.show = true;
        },

        previous(){
            this.month = new Date(this.month.setMonth(this.month.getMonth()-1));
            this.refresh_month++;
        },

        next(){
            this.month = new Date(this.month.setMonth(this.month.getMonth()+1));
            this.refresh_month++;
        }
    }
}
</script>
<style scoped>
.app-local-input .app-datepicker{
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-left: none !important;
}

.app-local-input .app-datepicker:hover{
    box-shadow: none !important;
    
}

.app-local-input-group{
    transition: none !important;
}

.app-local-input-group:hover{
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1) !important;
}

.app-local-input-group .app-input-button-group{
    transition: none !important;
}

.app-local-input-group:hover .app-input-button-group{
    border-top-color: #066791 !important;
    border-left-color: #066791 !important;
    border-bottom-color: #066791 !important;

}

.app-local-input-group:hover .app-datepicker{
    border-color: #066791 !important;
}

.app-local-input-group:focus-within .app-input-button-group{
    border-top-color: #066791 !important;
    border-left-color: #066791 !important;
    border-bottom-color: #066791 !important;
}

.app-input-button-group-filled{
    border-top-color: #066791 !important;
    border-left-color: #066791 !important;
    border-bottom-color: #066791 !important;

}

.app-local-prepend{
    border: 1px solid #D0D5DD;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.app-local-input-group:hover .app-local-prepend, .app-local-input-group:focus-within .app-local-prepend{
    border-color: #066791;
    border-right-color: #D0D5DD !important;
}

.app-local-prepend-filled{
    border-color: #066791;
    border-right-color: #D0D5DD !important;
}

.app-local-left-button{
    border-right: 1px solid #D0D5DD !important;
}

.custom-icon-color{
    color: #066791
}
</style>