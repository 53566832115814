<template>
    <b-modal ref="modal-add" size="xl" v-model="computedShow" no-close-on-backdrop title="Components">

            <b-card>
                <app-view-table :isTitle="false" :fields="fields" :filters="filters"  :items="components" :loading="loading">

                    <template v-slot:menu-items="cell">
                        <b-dropdown-item @click="change(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                        <b-dropdown-item @click="removeComponent(cell.data.item)">{{trans('remove',175)}}</b-dropdown-item>
                        
                    </template>

                    <template #buttons>
                        <app-button type="add" @click="add">{{trans('add', 175)}}</app-button>
                    </template>

                </app-view-table>

            </b-card>


        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
        </template>

        <people-person-profile-mobile-hours-components-change v-if="mChange.show" :show.sync="mChange.show" :args.sync="mChange.args" :result.sync="mChange.result" :return.sync="mChange.return" />
        <people-person-profile-mobile-hours-components-add-step-1 v-if="mAddStep1.show" :show.sync="mAddStep1.show" :args.sync="mAddStep1.args" :result.sync="mAddStep1.result" :return.sync="mAddStep1.return" />
        <people-person-profile-mobile-hours-components-add-step-2 v-if="mAddStep2.show" :show.sync="mAddStep2.show" :args.sync="mAddStep2.args" :result.sync="mAddStep2.result" :return.sync="mAddStep2.return" />
    </b-modal>
</template>

<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import PeoplePersonProfileMobileHoursComponentsChange from './PeoplePersonProfileMobileHoursComponentsChange.vue';;
import PeoplePersonProfileMobileHoursComponentsAddStep1 from './PeoplePersonProfileMobileHoursComponentsAddStep1.vue';
import PeoplePersonProfileMobileHoursComponentsAddStep2 from './PeoplePersonProfileMobileHoursComponentsAddStep2.vue';
export default {
    components: {
        PeoplePersonProfileMobileHoursComponentsChange,
        PeoplePersonProfileMobileHoursComponentsAddStep1,
        PeoplePersonProfileMobileHoursComponentsAddStep2,
    },  

    props: ["show", "result", "args","return"],

    created(){
        this.getItem();
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },
         cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    watch: {
        "components.refresh": {
            handler: function(val){
                if(!this.loading){
                    this.getItem();
                }
            }
        },

        "mChange.result": {
            handler: function(val){
                if(!this.loading && val > 0){
                    this.getItem();
                    this.mChange.result = 0;
                }
            }
        },

        "mAddStep1.result": {
            handler: function(val){
                if(val > 0){
                    this.addStep2(this.mAddStep1.return.id_placement_const_component);
                    this.mAddStep1.result = 0;
                }
            }
        },

        "mAddStep2.result": {
            handler: function(val){
                if(!this.loading && val > 0){
                    this.getItem();
                    this.mAddStep2.result = 0;
                }
            }
        },
    },

    data() {
        return {
            alert_class: new AppAlerts(),
            custom_columns: ['value'],
            loading: false,
            components: {list: [], refresh: 0},
                        filters: {
                sorting: {
                        field_name: '',
                        sort_direction: 0,
                        changed: 0
                },
                search: "",
                page: 1,
                changed: 0,
                per_page: 10,
                total: 0,
            },
            fields:[
                { key: "name", label: this.trans('placement-wage-component',213), thStyle: { "min-width": "250px", "max-width": "250px" }, visible: true, filtering: false, sortable: false },
                { key: "value", label: this.trans('settings-value',207), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: false, sortable: false },
                { key: "fields.description.value", label: this.trans('description',182), thStyle: { "width": "100%" }, visible: true, filtering: false, sortable: false },
            ],
            mChange: { show: false, args:{
                id_shift_data_main: null,
                id_placement_const_component: null,
                topic: null,
                amount: null,
                distance: null,
                description: null,
                explanation: null,
                attachment: null
            }, result: 0, return: null},

            mAddStep1: { show: false, args:{ id_shift_data_main: null}, result: 0, return: null},
            mAddStep2: { show: false, args:{ id_shift_data_main: null, id_placement_const_component: null}, result: 0, return: null}

        };
    },

    methods: {
        getItem(){
            this.loading = true;
                axios
                    .post("v1/declaration/getDetailsById", {
                        id_shift_data_main: this.cArgs.id_shift_data_main
                    })
                    .then((res) => {
                        this.components.list = res.data.components;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(()=>{
                        this.loading = false;
                    });
        },

        change(item) {
            this.mChange.args.id_shift_data_main = this.cArgs.id_shift_data_main;
            this.mChange.args.id_placement_const_component = item.id;
            this.mChange.args.topic = item.topic;
            this.mChange.args.amount = item.fields.amount.value;
            this.mChange.args.distance = item.fields.distance.value;
            this.mChange.args.description = item.fields.description.value;
            this.mChange.args.explanation = item.fields.explanation.value;
            this.mChange.args.attachment = item.id == 13 ? item.fields.attachment.value : null;

            this.mChange.show = true;
        },

        add(){
            this.mAddStep1.args.id_shift_data_main = this.cArgs.id_shift_data_main;
            this.mAddStep1.show = true;
        },

        addStep2(id_placement_const_component){
            this.mAddStep2.args.id_placement_const_component = id_placement_const_component;
            this.mAddStep2.args.id_shift_data_main = this.cArgs.id_shift_data_main;
            this.mAddStep2.show = true;
        },

        removeComponent(item){
            this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {
                if(res == true) {
                    axios
                    .post("v1/declaration/removeComponent", {
                        id_shift_data_main: this.args.id_shift_data_main,
                        id_placement_const_component: item.id
                    })
                    .then((res) => {
                        
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(()=>{
                        this.getItem();
                    }); 
                }
            });
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
</style>
