<template>
    <b-modal ref="modal-filters" size="md" v-model="computedShow" no-close-on-backdrop title="Filters">
            <app-row-left-label label="Working area">
                <app-slider v-model="filters.working_area.radius" min="10" max="150" step="10" :key="refresh" showValue markLabel />
            </app-row-left-label>

            <app-row-left-label label="Sector">
                <app-multi-select v-model="filters.ids_sector_const_type" :customOptions="filters_options.sectors" :isAllOption="false" :key="refresh" addButtonLabel="Add sector" />
            </app-row-left-label>

            <app-row-left-label label="Targets">
                <app-multi-select v-model="filters.ids_target_data_group" :customOptions="getTargets()" :isAllOption="false" :disabled="filters.ids_sector_const_type.length > 1 || filters.ids_sector_const_type.length == 0" :key="refresh_targets" addButtonLabel="Add target" />
            </app-row-left-label>

            <app-row-left-label label="Clients">
                <app-multi-select v-model="filters.ids_clients_data_main" :customOptions="filters_options.clients" :isAllOption="false" :key="refresh" addButtonLabel="Add" />
            </app-row-left-label>

            <app-row-left-label label="Daytime">
                <app-checkbox-group v-model="filters.ids_tod_const_type" :options="filters_options.tod" :key="refresh"/>
            </app-row-left-label>

            <app-row-left-label label="Hours per shift" :slots="[6,6]">
                <template v-slot:col-1>
                    <app-input type="integer" v-model="filters.shift_length.from" />
                </template>
                <template v-slot:col-2>
                    <app-input type="integer" v-model="filters.shift_length.to" />
                </template>
            </app-row-left-label>
            <template #modal-footer>
                <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
                <app-button type="primary" @click="saveFilters" :loading="loading">
                    {{trans('save',175)}}
                </app-button>
            </template>

    </b-modal>
</template>
<script>
import axios from "@axios";
import AppSlider from '../../../../../@core/components/AppSlider.vue';

export default {

    props: ["show", "args", "return", "result"],

    components: {
        AppSlider

    },

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        }
    },

    watch: {
        "filters.ids_sector_const_type":{
            handler: function(val){
                this.ids_target_data_group = [];
                this.refresh_targets++;
            },
            deep: true
        }
    },

    created(){
        this.getFiltersOptions();
        this.getFilters();
    },
    
    data(){
        return {
            filters: {
                ids_sector_const_type: [],
                ids_tod_const_type: [],
                ids_clients_data_main: [],
                ids_target_data_group: [],
                shift_length: {
                    to: null,
                    from: null
                },
                
                working_area: {
                    radius: null
                }

            },

            filters_options: {
                sectors: [],
                targets: [],
                clients: [],
                tod: [],
            },
            loading: false,
            refresh: 0,
            refresh_targets: 0,
        }
    },

    methods: {
        getFilters(){
            this.loading = true;
            axios
                .get('v1/user/getFilters/'+this.args.id_person_data_main)
                .then((res) => {
                    this.filters.ids_sector_const_type = 'ids_sector_const_type' in res.data ? res.data.ids_sector_const_type : [];
                    this.filters.ids_tod_const_type = 'ids_tod_const_type' in res.data ? res.data.ids_tod_const_type : [];
                    this.filters.ids_clients_data_main = 'ids_clients_data_main' in res.data ? res.data.ids_clients_data_main : [];
                    this.filters.ids_target_data_group = 'ids_target_data_group' in res.data ? res.data.ids_target_data_group : [];
                    this.filters.shift_length = 'shift_length' in res.data ? res.data.shift_length : { to: null, from: null};
                    this.filters.working_area = res.data.working_area;
                    this.refresh++;
                })
                .catch((error) => {
                    console.log(`error: ${error}`);
                })
                .finally(() =>{
                    this.loading = false;
                    
                })

        },

        getFiltersOptions(){
            this.loading = true;
            axios
                .get('v1/core/getFilters')
                .then((res) => {
                    this.filters_options = res.data;

                    this.filters_options.sectors = res.data.sectors.map((item) => {
                        return {id: item.id, label: item.label, value: item.id, name: item.label};
                    });

                    this.filters_options.clients = res.data.clients.map((item) => {
                        return {id: item.id, label: item.label, value: item.id, name: item.label};
                    });

                    this.filters_options.targets = res.data.targets.map((item) => {
                        return {id: item.id, label: item.label, value: item.id, name: item.label};
                    });

                    this.filters_options.tod = res.data.tod.map((item) => {
                        return {id: item.id, label: item.label, value: item.id, text: item.label};
                    });
                    this.refresh++;
                })
                .catch((error) => {
                    console.log(`error: ${error}`);
                })
                .finally(() =>{
                    
                })
        },

        getTargets(){
            if(this.filters.ids_sector_const_type.length == 1 && this.filters.ids_sector_const_type[0] != 5){
                var t =  this.filters_options.sector_with_targets.filter((item) => item.id_sector_const_type == this.filters.ids_sector_const_type[0])[0]
                return this.filters_options.targets.filter((item) => {
                    return t.ids_target_data_group.includes(item.id);
                })
            } else {
                return [];
            }
        },

        saveFilters(){
                        this.loading = true;
            axios
                .post('v1/user/saveFilters',{
                    id_person_data_main: this.args.id_person_data_main,
                    filters: this.filters
                })
                .then((res) => {
                    console.log(res.data);
                    this.$emit('update:result', 1);
                })
                .catch((error) => {
                    console.log(`error: ${error}`);
                })
                .finally(() =>{
                    this.loading = false;
                    this.computedShow = false;
                })
        },

        closeModal() {
            this.$emit("update:show", false);
        },
    }
}
</script>
<style scoped>

</style>