<template>
    <div>
        <b-row class="mb-3">
            <b-col cols="12">
                <people-person-profile-mobile-status-bar v-if="!loading" :user.sync="user" :type.sync="type" />
            </b-col>
        </b-row>
        
        <b-card v-if="!loading">

            <people-person-profile-mobile-shifts v-if="type == 1" />
            <people-person-profile-mobile-hours v-if="type == 2" />
            <people-person-profile-mobile-settings v-if="type == 3" />
            <people-person-profile-mobile-notifications v-if="type == 4" />
        </b-card>
    </div>
</template>
<script>
import axios from "@axios";
import PeoplePersonProfileMobileStatusBar from './Components/PeoplePersonProfileMobileStatusBar.vue';
import PeoplePersonProfileMobileShifts from './Components/PeoplePersonProfileMobileShifts.vue';
import PeoplePersonProfileMobileSettings from './Components/PeoplePersonProfileMobileSettings.vue';
import PeoplePersonProfileMobileHours from './Components/PeoplePersonProfileMobileHours.vue';
import PeoplePersonProfileMobileNotifications from './Components/PeoplePersonProfileMobileNotifications.vue';

export default {

    components: {
        PeoplePersonProfileMobileStatusBar,
        PeoplePersonProfileMobileShifts,
        PeoplePersonProfileMobileSettings,
        PeoplePersonProfileMobileHours,
        PeoplePersonProfileMobileNotifications,
    },

    created(){
        this.getData();
    },

    data(){
        return {
            id_person_data_main: this.$route.params.id_person_data_main,
            user: null,
            type: 1,
            loading: false
        }
    },

    methods: {
        getData(){
            this.loading = true;
            axios
                .get('v1/user/'+ this.id_person_data_main)
                .then((res) => {
                    this.user = res.data.user;
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    }

    
}
</script>
<style scoped>

</style>