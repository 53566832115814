<template>
        <div>
        
                <app-view-table title="Open" :isTitle="true" :fields="fields" :items="items" :filters="filters" :loading="loading_list" :topFiltersCount="1" class="mb-3">

                    <template v-slot:menu-items="cell">
                        <b-dropdown-item @click="openShift(cell.data.item)">{{trans('to-service',200)}}</b-dropdown-item>
                    </template>

                </app-view-table>


            <people-person-profile-mobile-shift-profile v-if="mShift.show" :show.sync="mShift.show" :args.sync="mShift.args" :result.sync="mShift.result"  :return.sync="mShift.return"/>
            
        </div>
</template>
<script>
import axios from "@axios";
import moment from "moment";
import PeoplePersonProfileMobileShiftProfile from './PeoplePersonProfileMobileShiftProfile.vue'

export default {

    components: {
        PeoplePersonProfileMobileShiftProfile
    },

    created(){
    },

    mounted(){
        this.getList();
    },

    props: ["filters", "refresh"],

    computed: {

        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        },
    },

    watch: {
        "refresh": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "items.refresh": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "items_planned.refresh": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "items_requested.refresh": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "items_rejected.refresh": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },


        "filters.per_page": {
            handler: function(val){
                if(!this.loading_list){
                    this.filters.page = 1;
                    this.getList();
                }
            }
        },

        "filters.page": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.filters.page = 1;
                    this.getList();
                }
            }
        },

        "filters.sorting.changed":{
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.client_names.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.client_locations.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.client_departments.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.functions.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.dates.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "mShift.result": {
            handler: function(val){
                if(!this.loading_list &&this.mShift.result == 1){
                    this.getList();
                    this.mShift.result = 0;
                }
            }
        }
        
    },  

    data(){
        return{
            id_person_data_main: this.$route.params.id_person_data_main,
            loading: false,
            loading_list: false,
            items: {list: [], total: null, refresh :0},

            fields:[
                { key: "client_name", label: this.trans('organization',169), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: false, filtering_name: "client_names"},
                { key: "location_name", label: this.trans('location',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: false, filtering_name: "client_locations"},
                { key: "department_name", label: this.trans('department',182), thStyle: { "width": "100%" }, visible: true, filtering: false, filtering_name: "client_departments"},
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: false, filtering_name: "functions"},
                { key: "date_from", label: this.trans('date',182), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: false, filtering_name: "dates"},
                { key: "time_from", label: this.trans('planning-begin',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: false},
                { key: "time_to", label: this.trans('planning-end',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: false},
                { key: "shift_daytime_name", label: this.trans('planning-service-type',209), thStyle: { "min-width": "90px", "max-width": "90px" }, visible: true, filtering: false},
                { key: "status_name", label: this.trans('status',3), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: false},
            ],
            clients: null,
            shift_profile: false,
            id_shift_data_main: null,

            mShift: {show: false, args: {id_person_data_main: null, id_shift_data_main: null}, result: 0, return: null}
        }
    },

    methods: {

        getList(){
            this.loading_list = true;

                axios
                    .post('v1/plan/getOpen', {
                        id_person_data_main: this.id_person_data_main,
                        search: this.filters.search,
                        page: 1,
                        per_page: 50,
                        total: 0,
                        period: {
                            date_from: this.filters.period.date_from,
                            date_to: this.filters.period.date_to,
                        }
                    })
                    .then((res) => {
                        this.items.list = res.data.items;

                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading_list = false;
                    });
               
            
        },

        openShift(item){
            this.mShift.args.id_shift_data_main = item.item_id;
            this.mShift.args.id_person_data_main = this.id_person_data_main;
            this.mShift.show = true;
        },


        closeModal() {
            this.$emit("update:show", false);
        },

    }
    
}
</script>
<style scoped>

</style>