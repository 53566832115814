<template>
    <div>
        <b-row class="mb-3">
            <b-col>
                Shifts
            </b-col>
            <b-col cols="auto" end v-if="type > 1">
                <app-button type="primary" @click="openFilters">Filters</app-button>
            </b-col>
            <b-col cols="2" end v-if="type > 1" class="px-0">
                <app-input v-model="search" @blur="updateSearch" />
            </b-col>
            <b-col cols="2" end v-if="type > 1" class="pr-0">
                <app-date v-model="period" range :isDefaultFormat="false"  />
            </b-col>
            <b-col end cols="2">
                <app-select v-model="type" :options="options" reduceValue />
            </b-col>
        </b-row>

        <people-person-profile-mobile-my-shifts v-if="type == 1" />
        <people-person-profile-mobile-open-shifts v-if="type == 2" :filters.sync="filters" :refresh.sync="refresh_list" />
        <people-person-profile-mobile-completed-shifts v-if="type == 3" :filters.sync="filters" :refresh.sync="refresh_list" />

        <people-person-profile-mobile-shifts-filters v-if="mFilters.show" :show.sync="mFilters.show" :args.sync="mFilters.args" :result.sync="mFilters.result" :return.sync="mFilters.return" />

    </div>
</template>
<script>

import PeoplePersonProfileMobileMyShifts from './PeoplePersonProfileMobileMyShifts.vue';
import PeoplePersonProfileMobileOpenShifts from './PeoplePersonProfileMobileOpenShifts.vue';
import PeoplePersonProfileMobileCompletedShifts from './PeoplePersonProfileMobileCompletedShifts.vue';
import PeoplePersonProfileMobileShiftsFilters from './PeoplePersonProfileMobileShiftsFilters.vue';

import moment from "moment";
import axios from "@axios";

export default {
    components: {
        PeoplePersonProfileMobileMyShifts,
        PeoplePersonProfileMobileOpenShifts,
        PeoplePersonProfileMobileShiftsFilters,
        PeoplePersonProfileMobileCompletedShifts
    },

    created(){
        this.getFilters();
    },

    data() {
        return{
        options: [
            {name: 'My shifts', value: 1},
            {name: 'Open shifts', value: 2},
            {name: 'Completed shifts', value: 3},
           ],
           type: 1,
           search: null,
           refresh_list: 0,
           mFilters: {show: false, args: { id_person_data_main: null }, result: 0, return: 0},
           period: null,
           filters: {
                search: "",
                page: 1,
                changed: 0,
                per_page: 10,
                total: 0,
                period: {
                    date_from: null,
                    date_to: null
                }

            },
        }
    },

    watch:{
        "mFilters.result":{
            handler: function(val){
                if(val == 1){
                    this.refresh_list++;
                    this.mFilters.result = 0;
                }
            }
        },

        "period":{
            handler: function(val){
                if(val[0] != null && val[1] != null){
                    this.filters.period.date_from = moment(this.period[0]).format('YYYY-MM-DD');
                    this.filters.period.date_to = moment(this.period[1]).format('YYYY-MM-DD');
                    this.refresh_list++;
                }
            }
        },

    },

    methods:{
        openFilters(){
            this.mFilters.args.id_person_data_main = this.$route.params.id_person_data_main;
            this.mFilters.show = true;
        },

        getFilters(){
            this.loading = true;
            axios
                .get('v1/user/getFilters/'+this.$route.params.id_person_data_main)
                .then((res) => {
                    this.filters.period.date_from = res.data.period ? res.data.period.date_from : null;
                    this.filters.period.date_to = res.data.period ? res.data.period.date_to : null;
                    this.period = [
                        new Date(res.data.period.date_from),
                        new Date(res.data.period.date_to)
                    ]
                })
                .catch((error) => {
                    console.log(`error: ${error}`);
                })
                .finally(() =>{
                    
                })
        },

        updateSearch(event){
            this.filters.search = this.search;
            this.refresh_list++;
        }
    }
}
</script>
<style scoped>

</style>