<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('date-employment-change',8)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent>
                <app-row-left-label label="Begin en eindtijd" :slots="[6,6]">
                    <template v-slot:col-1>
                        <app-time v-model="cArgs.hour_from" />
                    </template>
                    <template v-slot:col-2>
                        <app-time v-model="cArgs.hour_to" />
                    </template>
                </app-row-left-label>

                <app-row-left-label label="Pause">
                    <app-time v-model="pause" validatorName="Pauze" valueType="mm" placeholder="MM" format="mm" :minuteStep="5"/>
                </app-row-left-label>

                <app-row-left-label label="Description">
                    <app-memo lettersCounter v-model="description" />
                </app-row-left-label>

            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change()" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args","return"],

    created(){
        this.getItem();
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },
         cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading: false,
            description: '',
            pause: null,
        };
    },

    methods: {
        getItem(){
            this.loading = true;
                axios
                    .post("v1/declaration/getDetailsById", {
                        id_shift_data_main: this.cArgs.id_shift_data_main
                    })
                    .then((res) => {
                        this.description = res.data.description;
                        this.pause = res.data.pause;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(()=>{
                        this.loading = false;
                    });
        },

        change() {

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("v1/declaration/change", {
                            id_shift_data_main: this.cArgs.id_shift_data_main,
                            hour_from: this.cArgs.hour_from,
                            hour_to: this.cArgs.hour_to,
                            pause: '00:'+this.pause,
                            description: this.description

                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                    }
            })
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
</style>
