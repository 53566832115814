<template>
    <div>
        <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('placement-add-component',222)">
            <b-card class="app-local-card-size">
                <div class="app-fixed-search d-flex align-items-center">
                    <div class="d-flex align-content-center justify-content-between w-100">
                        <b-input-group class="input-group-merge" >
                        
                            <b-input-group-prepend is-text class="app-local-icon">
                                <b-icon icon="search" class="text-muted" v-if="!loading"></b-icon>      
                                <b-spinner class="align-middle" small v-if="loading"></b-spinner>  
                            </b-input-group-prepend >

                            <b-form-input class="pl-2 mt-1 border-0" autofocus :placeholder="trans('search',3)" v-model="filters.search" :lazy="true"/>

                            <b-input-group-append is-text class="app-local-dropdown">
                                <b-dropdown variant="link" no-caret right>
                                    <template #button-content >
                                            <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                                    </template>
                                        <b-dropdown-item @click="filters.sort_desc = false"> {{trans('sort-az',260)}} </b-dropdown-item>
                                        <b-dropdown-item @click="filters.sort_desc = true"> {{trans('sort-za',260)}} </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </div>
                <hr class="app-search-modal-separator">
                    <b-list-group flush class="app-local-list-group-custom-style">
                        <b-list-group-item class="d-flex  align-items-center app-local-list-group-item-custom-style" action v-for="item in items" :key="item.id" tabindex="0" @click.prevent="selectItem(item)">
                        <b-form-checkbox  v-model="component" :value="item" :unchecked-value="null" size="sm">
                        <span class="app-documents-item">{{item.name}}</span></b-form-checkbox>                    
                        </b-list-group-item>
                    </b-list-group>
            </b-card>
            <template #modal-footer>
                <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
                <app-button type="primary"  @click="addComponent" :loading="loading_data">
                    {{trans('next-step',175)}}
                </app-button>
            </template>
        </b-modal>
    </div> 
</template>

<script>
import axios from "@axios";

export default {

    props: ["show", "result", "args", "return"],

    created(){
        this.getList();
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },     

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }

    },

    watch: {
        filters: {
            handler: function(val) {
                if (this.filters.sort_by != "") {
                    this.loading = true;
                    this.getList();
                }
            },
            deep: true,
        },
        filters: {
            handler: function(val) {
                this.getList();
            },
            deep: true,
        },

    },

    data() {
        return {
            component: null,
            filters:{
                search:"",
                sort_by: "id",
                sort_desc: false,
                args:[],
            },
            loading: false,
            loading_data: false,
            items:[],
        };
    },


    methods: {

        selectItem(item) {          
           var index = this.items.indexOf(item);
           if (item.selected === '1') {
                item.selected = '0';
            } else {
                item.selected = '1';               
            }

            this.component = item;
            this.$set(this.items, index, item);
        },

       getList: function() {
            this.loading = true;
            axios
                .post("v1/declaration/getComponentsTypes",{
                    id_shift_data_main: this.args.id_shift_data_main
                })
                .then((res) => {
                    this.items = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = 0;
                });
        },

        addComponent() {
           
            if (this.component != null) {

                let item = {
                    client_change : this.component.client_change,
                    client_value : this.component.client_value,
                    id_placement_const_component : this.component.id,
                    name : this.component.name,
                    person_change : this.component.person_change,
                    person_value : this.component.person_value,
                    type : this.component.type,
                    topic: "",
                    amount: null,
                    distance: null,
                    description: "",
                    explanation: "",
                    attachment: ""
                };

                this.cReturn = item;
                this.$emit("update:show", false);
                this.$emit("update:result", 1);
            }
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
.app-local-card-size{
    min-height:300px !important; 
    max-height:600px !important
}
.app-local-icon{
    width:40px !important;
}
.app-local-dropdown{
    width:65px
}
.app-local-list-group-custom-style{
    max-height:300px !important;
    overflow-y: auto;
}
.app-local-list-group-item-custom-style{
    cursor:pointer; 
    padding:4px 4px;
}
.input-group-text {
    border: none;
}
.ps{
    height: 350px;
}
.card-body {
    padding-top: 0.5rem;
}
</style>
