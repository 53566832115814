<template>
    <b-modal ref="modal-shift" size="md" v-model="computedShow" no-close-on-backdrop title="Hours details">
        
        <b-overlay :show="loading" rounded="sm" blur="2px">
            <b-card class="mt-2" style="min-height: 200px;">
                <div v-if="!loading">
                    <div class="d-flex justify-content-between"> <b class="mb-0">{{getDate()}}</b></div>
                        
                    <hr class="mt-2 mb-2" />

                    <span class="col-list-label"><b>{{ trans('declarations',209) }}</b></span>

                    <b-row v-for="item in details_data.components" :key="item.name">
                        <b-col cols="8">
                            <span class="col-list-label" v-overflow-tooltip>{{item.name}}</span>
                        </b-col>
                        <b-col cols="4" class="text-right" >
                            <span class="col-list-label">€ {{parseFloat(item.value).toFixed(2).replace('.',',')}}</span>
                        </b-col>
                    </b-row>

                    <hr class="mt-2 mb-2" />
                    <span class="col-list-label"><b>{{ trans('sh-hours',215) }}</b></span>
                    <b-row v-for="item in details_data.hours" :key="item.period">
                        <b-col cols="6" >
                            <span class="col-list-label">{{item.period}}</span>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <span class="col-list-label">{{item.label}}</span>
                        </b-col>
                    </b-row>

                </div>
            </b-card>
        </b-overlay>

        <template #modal-footer>

            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
        </template>
    </b-modal>
</template>
<script>

import axios from "@axios";
import moment from "moment";

export default {

    props: ["show", "args", "return", "result"],
    components: {

    },

    computed: {
        cVariant: {
            get(){
                if(this.details_data.item_status == 3){
                    return 'success'
                } else if(this.details_data.item_status == 2){
                    return 'warning'
                } else if(this.details_data.item_status == 4){
                    return 'danger'
                } else {
                    return 'secondary'
                }
            }
        },

        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },
    },

    created(){
        this.getData();
    },

    data(){
        return {
            loading: false,
            details_data: null,
            components: null
        }
    },

    methods: {

        getData(){
            this.loading = true;

            axios
                .post('v1/declaration/getDetailsById',{
                    id_shift_data_main: this.args.id_shift_data_main,
                    id_person_data_main: this.args.id_person_data_main
                })
                .then((res) => {
                    this.details_data = res.data;
                    this.getComponentsTypes();
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                })

        },

        getComponentsTypes(){
            this.loading = true;

            axios
                .post('v1/declaration/getComponentsTypes',{
                    id_shift_data_main: this.args.id_shift_data_main,
                    id_person_data_main: this.args.id_person_data_main
                })
                .then((res) => {
                    this.components = res.data;
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                })

        },

        getDate(){
            var date =  moment(this.details_data.date_from, 'YYYY-MM-DD');

            return date.format('DD-MM-YYYY')+', ' + this.details_data.time_from + ' - ' + this.details_data.time_to;
        },

        closeModal() {

            
            this.$emit("update:show", false);
        },

    }
    
}
</script>
<style scoped>
.app-local-back:hover{
    cursor: pointer;
    color:#8EC1F0;
}
</style>