<template>
    <b-modal ref="modal-shift" size="md" v-model="computedShow" no-close-on-backdrop title="Dienst">
        
        <b-overlay :show="loading" rounded="sm" blur="2px">
            <b-card class="mt-2" style="min-height: 200px;">
                <div v-if="!loading">
                    <div class="d-flex justify-content-between mb-3"> <div><b-badge variant="secondary" class="mr-3">Dienst</b-badge> <b-badge :variant="cVariant">{{shift_data.status_name}}</b-badge></div> <b-icon icon="sun" class="mr-2"></b-icon></div>
                    <div class="d-flex justify-content-between"> <b class=" mb-0">{{getDate()}}</b></div>

                    <hr class="my-2"/>
                    <!-- <b-icon icon="building" class="mr-2"></b-icon> {{shift_data.office_name}}, {{shift_data.department_name}} <br />
                    <b-icon icon="people" class="mr-2"></b-icon> {{shift_data.function_name}} <br /> 
                    <b-icon icon="geo-alt" class="mr-2"></b-icon> {{shift_data.location_street}} {{shift_data.location_house_number}} <br />
                    <b-icon icon="telephone" class="mr-2"></b-icon> {{shift_data.location_phone_number}} <br /> -->
                

                <b-row class="app-card-header">
                    <b-col start>
                        <p class="col-list-label">Vestiging</p>
                        <p class="col-list-label" >Organisatie</p>
                        <p class="col-list-label" >Locatie</p>
                        <p class="col-list-label mb-3" >Afdeling</p>
                        <p class="col-list-label" >Straat</p>
                        <p class="col-list-label" >Huisnummer</p>
                        <p class="col-list-label" >Huisnummer toevoeging</p>
                        <p class="col-list-label" >Postcode</p>
                        <p class="col-list-label mb-3" >Plaats</p>
                        <p class="col-list-label" >Functie</p>
                        <p class="col-list-label" >Dagdeel</p>

                    </b-col>
                    <b-col>
                        <p class="col-form-value">{{shift_data.office_name ? shift_data.office_name : '-'}}</p>
                        <p class="col-form-value">{{shift_data.client_name ? shift_data.client_name : '-'}}</p>
                        <p class="col-form-value">{{shift_data.location_name ? shift_data.location_name : '-'}}</p>
                        <p class="col-form-value mb-3">{{shift_data.department_name ? shift_data.department_name : '-'}}</p>
                        <p class="col-form-value">{{shift_data.location_street ? shift_data.location_street : '-'}}</p>
                        <p class="col-form-value">{{shift_data.location_house_number ? shift_data.location_house_number : '-'}}</p>
                        <p class="col-form-value">{{shift_data.location_number_additional ? shift_data.location_number_additional : '-'}}</p>
                        <p class="col-form-value">{{shift_data.location_post_code ? shift_data.location_post_code : '-'}}</p>
                        <p class="col-form-value mb-3">{{shift_data.location_name ? shift_data.location_name : '-'}}</p>
                        <p class="col-form-value">{{shift_data.function_name ? shift_data.function_name : '-'}}</p>
                        <p class="col-form-value">{{shift_data.shift_daytime_name ? shift_data.shift_daytime_name : '-'}}</p>
                    </b-col>
                </b-row>
                </div>

            </b-card>
        </b-overlay>

        <template #modal-footer>
            <app-button type="primary" v-if="!loading && shift_data.item_status == 1" @click="register" :loading="loading">
                Direct aanmelding
            </app-button>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
        </template>
    </b-modal>
</template>
<script>

import axios from "@axios";
import moment from "moment";

export default {

    props: ["show", "args", "return", "result"],
    components: {

    },

    computed: {
        cVariant: {
            get(){
                if(this.shift_data.item_status == 3){
                    return 'success'
                } else if(this.shift_data.item_status == 2){
                    return 'warning'
                } else if(this.shift_data.item_status == 4){
                    return 'danger'
                } else {
                    return 'secondary'
                }
            }
        },

        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },
    },

    created(){
        this.getData();
    },

    data(){
        return {
            loading: false,
            shift_data: null
        }
    },

    methods: {

        getData(){
            this.loading = true;

            axios
                .post('v1/plan/getItemDetails',{
                    item_id: this.args.id_shift_data_main,
                    item_type: 1,
                    id_person_data_main: this.args.id_person_data_main
                })
                .then((res) => {
                    this.shift_data = res.data;
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                })

        },

        register(){
            this.loading = true;

            axios
                .post('v1/plan/registerToItem',{
                    item_id: this.args.id_shift_data_main,
                    item_type: 1,
                    id_person_data_main: this.args.id_person_data_main
                })
                .then((res) => {
                    this.$emit("update:result", 1);
                    this.$emit("update:show", false);
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                })

        },

        getDate(){
            var date =  moment(this.shift_data.date_from, 'YYYY-MM-DD');

            return date.format('DD-MM-YYYY') + ', ' + this.shift_data.time_from + ' - ' + this.shift_data.time_to;
        },

        closeModal() {

            
            this.$emit("update:show", false);
        },

    }
    
}
</script>
<style scoped>
.app-local-back:hover{
    cursor: pointer;
    color:#8EC1F0;
}
</style>