<template>
    <b-row>
        <b-col cols="10" class="d-flex align-items-center">
            <img class="rounded-circle custom-avatar mt-1" v-if="user.avatar != null" :src="user.avatar" alt="Avatar" />
            <img class="rounded-circle custom-avatar mt-1" v-else src="@/assets/images/avatars/female.jpg" alt="Avatar" />
            <div class="app-local-name ml-2">{{user.first_name}} {{user.inbetween_name}} {{user.last_name}}</div>
        </b-col>
        <b-col cols="2" end>
            <app-select v-model="cType" :options="options" reduceValue />
        </b-col>
    </b-row>
</template>
<script>
export default {

    props:{
        user: {type: Object},
        type: {type: Number}
    },

    components: {

    },

    computed: {
        cType: {
            get() { return this.type },
            set(value) { this.$emit('update:type', value) }
        }
    },

    created(){
        
    },

    data(){
        return {    
            options: [
            {name: 'Shifts', value: 1},
            {name: 'Hours', value: 2},
            {name: 'Settings', value: 3},
            {name: 'Notifications', value: 4},
           ],
            
        }
    },

    methods: {

    }

    
}
</script>
<style scoped>
.app-local-name{
    font-size:24px;
}

.custom-avatar{
    height: 50px;
    width: 50px;
}
</style>