<template>
    <div>
        Settings

        
    </div>
</template>
<script>

import axios from "@axios";


export default {

    props: ["show", "args", "return", "result"],
    
    created(){
        this.getData();
    },

    methods: {

        getData(){
            this.loading = true;

            axios
                .get('v1/user/getProfileData/'+ this.$route.params.id_person_data_main)
                .then((res) => {
                    this.shift_data = res.data;
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                })

        },
    }
}
</script>
<style scoped>

</style>