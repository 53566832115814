import { render, staticRenderFns } from "./PeoplePersonProfileMobileSettings.vue?vue&type=template&id=91e4031c&scoped=true&"
import script from "./PeoplePersonProfileMobileSettings.vue?vue&type=script&lang=js&"
export * from "./PeoplePersonProfileMobileSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91e4031c",
  null
  
)

export default component.exports